@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --brand: #b5002f;
}

body {
  @apply transition-all;
  @apply bg-gray-100;
  @apply text-gray-900;
  @apply dark:text-gray-100;
  @apply dark:bg-gray-900;
}

.section-heading {
  @apply font-bold;
  /* @apply text-sm; */
  @apply mb-2;
  /* @apply lg:text-xl;
  @apply xl:text-2xl; */
}
.small-heading {
  @apply font-extralight;
  @apply text-sm;
  /* @apply lg:text-xl; */
  @apply xl:text-lg;
}
.xsmall-heading {
  @apply font-extralight;
  @apply text-sm;
  /* @apply lg:text-xl; */
  /* @apply xl:text-lg; */
}
.xsmall-bold-heading {
  @apply font-bold;
  @apply text-sm;
  /* @apply lg:text-xl; */
  /* @apply xl:text-lg; */
}

.page-heading {
  @apply font-bold;
  @apply text-4xl;
  @apply lg:text-4xl;
  @apply xl:text-8xl;
}

.mega-heading {
  @apply font-bold;
  @apply text-2xl;
  @apply md:text-4xl;
  @apply lg:text-8xl;
  @apply xl:text-8xl;
}

.list-heading {
  @apply font-bold;
  @apply text-2xl;
  @apply md:text-3xl;
  @apply lg:text-4xl;
  @apply xl:text-4xl;
}
/* 
.slick-slide {
  width: 400px;
} */

.site-logo {
  max-height: 35px;
  width: auto;
  min-width: 30px;
}

@media (max-width: 992px) {
  body {
    padding-bottom: 80px;
  }
}

.featured {
  min-height: 60vh;
}

@media (max-height: 768px) and (max-width: 1281px) and (min-width: 960px) {
  .featured {
    min-height: 60vh !important;
  }
}

.program-image {
  width: 100px;
}

.slick-slide {
  width: 60vw;
}
@media (min-width: 576px) {
  .slick-slide {
    width: 280px;
  }
}

@media (min-width: 992px) {
  .slick-slide {
    width: 360px;
  }
  .featured {
    min-height: 600px;
  }
}

.slick-arrow.slick-next {
  top: -20px !important;
  right: 40px !important;
}

.slick-arrow.slick-prev {
  top: -20px !important;
  right: 80px !important;
  left: auto !important;
}

#site-header {
  @apply py-1;
  @apply md:py-2;
  @apply lg:py-3;
}

.slick-arrow:before {
  @apply text-black !important;
  @apply dark:text-white !important;
}

a {
  /* @apply hover:text-brand; */
}

.marquee {
  padding-right: 40px;
}

.banner-ad img {
  width: 520px;
  height: auto;
  max-width: 100%;
}

.featured-title {
  min-height: 64px;
}
